import "../style.scss";

$(document).ready(function ($) {
	// refer button hide
	$("button.hide").click(function (e) {
		$(".refer").toggleClass("hidden");
	});

	//switch site vet
	$("#switch-vet").change(function () {
		if (this.checked) {
			//**LocalSite**location.replace("http://pet.localhost:3000/home");
			//**DevSiteChanges**location.replace("http://pet.rata.intercitystudio.com/home");
			location.replace("http://pet.bocc.dev/home");
		}
	});

	//switch site pet
	$("#switch-pet").change(function () {
		if (this.checked) {
			//**LocalSite**location.replace("http://vet.localhost:3000/home");
			//**DevSiteChanges**location.replace("http://vet.rata.intercitystudio.com/home");
			location.replace("http://vet.bocc.dev/home");
		}
	});

	$("button.mobileNav").click(function (e) {
		$(".mobileNav__menu").toggleClass("open");
		$("button.mobileNav").toggleClass("open");
	});

	// staff tabbed content http://refills.bourbon.io/
	$(".form__entry").hide();
	$(".form__entry:first").show();

	$(".form__button").click(function (event) {
		event.preventDefault();

		$(".form__entry").hide();
		var activeTab = $(this).attr("rel");
		$("#" + activeTab).show();

		$(".form__button").removeClass("is-active");
		$(this).addClass("is-active");
	});

	// faq's accordion http://refills.bourbon.io/
	$(".faq__title").bind("click", function (e) {
		jQuery(this).parent().find(".faq__answer").slideToggle("fast"); // apply the toggle to the ul
		jQuery(this).parent().toggleClass("is-expanded");
		jQuery(this).toggleClass("is-active");
		e.preventDefault();
	});

	// staff accordion menu http://refills.bourbon.io/
	$(".js-accordion-trigger").bind("click", function (e) {
		jQuery(this).parent().find(".submenu").slideToggle("fast"); // apply the toggle to the ul
		jQuery(this).parent().toggleClass("is-expanded");
		jQuery(this).toggleClass("is-active");
		e.preventDefault();
	});

	// staff tabbed content http://refills.bourbon.io/
	$(".js-vertical-tab-content").hide();
	$(".js-vertical-tab-content:first").show();

	$(".js-vertical-tab").click(function (event) {
		event.preventDefault();

		$(".js-vertical-tab-content").hide();
		var activeTab = $(this).attr("rel");
		$("#" + activeTab).show();

		$(".js-vertical-tab").removeClass("is-active");
		$(this).addClass("is-active");

		$(".js-vertical-tab-accordion-heading").removeClass("is-active");
		$(
			".js-vertical-tab-accordion-heading[rel^='" + activeTab + "']"
		).addClass("is-active");
	});

	//http://leafo.net/sticky-kit/
	$("#sticky").stick_in_parent();

	//https://css-tricks.com/snippets/jquery/smooth-scrolling/
	// Select all links with hashes
	$('a[href*="#"]')
		// Remove links that don't actually link to anything
		.not('[href="#"]')
		.not('[href="#0"]')
		.click(function (event) {
			// On-page links
			if (
				location.pathname.replace(/^\//, "") ==
					this.pathname.replace(/^\//, "") &&
				location.hostname == this.hostname
			) {
				// Figure out element to scroll to
				var target = $(this.hash);
				target = target.length
					? target
					: $("[name=" + this.hash.slice(1) + "]");
				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					$("html, body").animate(
						{
							scrollTop: target.offset().top - 80,
						},
						500,
						function () {
							// Callback after animation
							// Must change focus!
							var $target = $(target);
							$target.focus();

							if ($target.is(":focus")) {
								// Checking if the target was focused
								return false;
							} else {
								$target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
								$target.focus(); // Set focus again
							}
						}
					);
				}
			}
		});
});
